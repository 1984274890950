<template>
  Hello world
  <!-- <div class="bg-white shadow-sm p-5 pt-6 form-lg  position-relative">
    <div class="mb-3">
      <span class="fnt-medium" >เทมเพลตผู้ใช้ล่าสุด</span>
    </div> -->

    <!-- <div class="card p-3 mb-3" v-for="template in listUserTemplate" :key="template.strID">
      <div class="d-flex justify-content-between">
      
        <p class="mb-2 fnt-medium">{{ template.strUserTemplateName}}</p>
        <p class="mb-2">{{ template.strDateTime }}</p>
      </div>
      <div class="d-flex">
        <div>    <TemplateOutline width="12"  height="16"   viewBox="0 0 12 16"/></div>
    <div>    <p class="ps-1 m-0">เทมเพลตเริ่มต้น</p></div>
  
      </div>
      
    </div> -->
  <!-- </div> -->
</template>

<script>
// import {
//   TemplateOutline
// } from "@/components/icon/index";
export default {
  // components: {
  //   TemplateOutline
  // },

  // data() {
  //   return {
  //     listUserTemplate: [
  //       {
  //         strID: 1,
  //         strUserTemplateName: "Thai student template default",
  //         strDateTime: "1 มี.ค. 2566"
  //       },
  //       {
  //         strID: 2,
  //         strUserTemplateName: "Foreign student template default",
  //         strDateTime: "26 ธ.ค. 2565"
  //       }

  //     ]
  //   }
  // },
}
</script>

<!-- <style lang="scss" scoped>
.card {
  border-radius: 4px;
  border: 1px solid $black-400;
 
}
</style> -->